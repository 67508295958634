.ais-ClearRefinements, .ais-SearchBox, .ais-SortBy {
  margin: 1em 0;
}

.ais-Pagination {
  margin-top: 1em;
}

.left-panel {
  float: left;
  width: 200px;
}

.right-panel {
  margin-left: 210px;
}

.ais-InstantSearch {
  max-width: 960px;
  margin: 0 auto;
  overflow: hidden;
}

.ais-Hits-item img {
  width: 100%;
  height: 100%;
  margin-bottom: .5em;
  margin-right: 1em;
}

.hit-name {
  margin-bottom: .5em;
}

.hit-description {
  color: gray;
  margin-bottom: .5em;
  font-size: 90%;
}

.hit-info {
  font-size: 90%;
}
/*# sourceMappingURL=index.68107e3d.css.map */
